<template>
  <v-container
    fluid
    class="py-0"
  >
    <v-row
      dense
      class="pa-4"
      v-if="userAlreadyInTeam"
    >
      <v-col
        cols="12"
        class="text-center"
      >
        {{ $t('userAlreadyInTeam') }}
      </v-col>
    </v-row>
    <v-row
      dense
      class="pa-4"
      v-else-if="emailOrUsernameExists && userEmailExists"
    >
      <v-col
        cols="12"
        class="text-center"
      >
        {{ $t('userEmailExists') }}
      </v-col>
    </v-row>
    <v-row
      dense
      class="pa-4"
      v-else-if="emailOrUsernameExists && userNameExists"
    >
      <v-col
        cols="12"
        class="text-center"
      >
        {{ $t('userFullNameExists') }}
      </v-col>
    </v-row>    
    <v-row
      dense
      class="pa-4 text-center"
      v-else
    >
      <v-col
        cols="12"
        v-if="userNameExists || userEmailExists"
      >
        {{ $t('userExists') }}
      </v-col>
      <v-col cols="12">
        {{ $t('beingInvited') }}
      </v-col>
      <v-col
        cols="12"
        class="title pt-5 text--primary"
      >
        {{ userName }}
      </v-col>
      <v-col
        cols="12"
        class="subtitle-2 text--primary"
      >
        {{ email }}
      </v-col>
      <v-col
        cols="12"
        class="subtitle-2 pb-5"
        v-if="roleName"
      >
        <span>{{ $t('with') }}</span> <span class="text--primary">{{ roleName }}</span> <span>{{ $t('access') }}</span>
      </v-col>
      <v-col cols="12">
        {{ $t('toYourTeam') }}
      </v-col>
    </v-row>
    <v-row class="pa-0">
      <v-col
        cols="12"
        class="px-0 pt-0"
      >
        <v-divider />
      </v-col>
    </v-row>
    <v-row
      justify="space-between"
      dense
      align="center"
    >
      <v-col
        cols="12"
        md="auto"
      >
        <v-row
          class="pa-0"
          dense
        >
          <v-col
            cols="12"
            md="auto"
            class="py-0"
          >
            <v-btn
              block
              large
              color="error"
              text
              :disabled="processing"
              @click="abort"
            >
              {{ $t('abort') }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="auto"
            class="py-0"
          >
            <v-btn
              block
              large
              color="error"
              :disabled="processing"
              @click="back"
            >
              {{ $t('previous') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="auto"
      >
        <v-btn
          block
          large
          color="primary"
          :loading="processing"
          :disabled="processing || userAlreadyInTeam || emailOrUsernameExists"
          @click="nextStep"
        >
          {{ $t('invite') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
	name: "TeamInviteFinal",
	props: {
		userNameExists: {
      type: Boolean
    },
		userEmailExists: {
      type: Boolean
    },
		userAlreadyInTeam: {
      type: Boolean
    },
    emailOrUsernameExists: {
      type: Boolean
    },
		userName: {
      type: String,
      default: ''
    },
		email: {
      type: String,
      default: ''
    },
		role: {
      type: Number,
      default: 0
    }
	},
	methods: {
		abort() {
			this.$emit('close')
		},
		back() {
			this.$emit('back')
		},
		nextStep() {
			this.$emit('next')
		},
	},
	computed: {
		processing() {
			return this.$store.state.isProcessingTeamInvite
		},
		roles() {
			return this.$store.state.roles
		},
		roleName() {
			let foundRole = this.roles.find(role => role.role_id === this.role)
			if(foundRole) {
				return foundRole.name
			} else {
				return null
			}
		}
	}
}
</script>

<style scoped>

</style>